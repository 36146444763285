<template>
  <div class="login" >
      <img src="../assets/img/loginlogo2.png" alt  class="imgage"/>
      <div class="loginContent" v-loading='loading'>
          <template v-if="!register">
            <h1 style="font-family:YouYuan;text-align:center;font-weight:normal;line-height:1;font-size:25px;margin-top:-14px;margin-bottom:20px">用户登录</h1>
            <el-tabs v-model="loginway" @tab-click="getVertify">
              <el-tab-pane label="二维码登录" name="first"></el-tab-pane>
              <el-tab-pane label="账号登录" name="second"></el-tab-pane>
            </el-tabs>
            <div class="loginQRcode" v-if="loginway=='first'">
              <div class="QRcode">
                  <div class="shade el-icon-warning-outline" v-if="shade">
                    <el-button class="refresh" @click="getTicket" type="text">点击刷新</el-button>
                  </div>
                  <img :src="QRcode" alt="" width="100%">
              </div>
              <p style="text-align:center;margin-top:20px;margin-bottom:10px">打开微信扫描二维码登录</p>
              <p style="text-align:center">
                <el-button type="text" style="margin-right:20px;padding-left:10px;color:red" @click="register=!register">立即注册</el-button>
                <el-button type="text" @click="toIndexPage">前往首页</el-button>
              </p>
            </div>
            <el-form v-if="loginway=='second'" class="el_form loginForm" :rules="rules" ref="form" :model="form">  
              <el-form-item prop="userAccount">
                <el-input size="big" placeholder="请输入账号" prefix-icon="el-icon-user-solid" v-model="form.userAccount"></el-input>
              </el-form-item>
              <el-form-item prop="userPassword">
                <el-input
                  @keyup.enter.native="login"
                  size="big"
                  prefix-icon="el-icon-unlock"
                  placeholder="请输入密码"
                  type="password"
                  v-model="form.userPassword"
                />
              </el-form-item>
              <el-form-item prop="verificationCode">
                <!-- <el-input  v-model="form.verificationCode" placeholder="请输入验证码" @keyup.enter.native="login"></el-input> -->
                <!-- <div style="display:inline-block" @click="getVertify">
                  <img :src="verifiImg" alt="" width="100%" style="cursor:pointer">
                </div> -->
              </el-form-item>
              <el-form-item>
                <el-radio-group v-model="form.language">
                  <el-radio label="1">中文</el-radio>
                  <el-radio label="2">日文</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" :loading="log" style="width:60px;height:30px" type="primary" @click="login()">登录</el-button>
                <el-button class="czbtn" style="width:60px;height:30px;margin-left:50px" @click="reset">重置</el-button><br>
                <p type='primary' style="margin-top:30px;font-size:13px;color:gray;cursor:pointer" @click="register=!register">点击立即注册>></p>
                <p type='primary' style="margin-top:0px;font-size:13px;color:gray;cursor:pointer" @click="forget">忘记密码?点击找回>></p>
              </el-form-item>
            </el-form>
          </template>
          <div v-if="register" style="width:350px;">
              <h1 style="font-family:YouYuan;text-align:center;font-weight:normal;line-height:1;font-size:25px;margin-top:-14px">用户注册</h1>
              <el-form style="padding:20px 30px 0 30px" ref='register' :model="registerInfo" :rules='registerRules'>
                  <el-form-item prop="userName">
                      <el-input v-model="registerInfo.userName" placeholder="您的称呼，如 深圳飞腾贸易有限公司"></el-input>
                  </el-form-item>
                  <el-form-item prop="userPhone">
                      <el-input v-model="registerInfo.userPhone" placeholder="手机号"></el-input>
                  </el-form-item>
                  <el-form-item prop="userAccount">
                      <el-input v-model="registerInfo.userAccount" placeholder="用户账号，仅支持字母，限制20个字符"></el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                      <el-input v-model="registerInfo.code" placeholder="手机验证码" style="width:58%;"></el-input>
                      <el-button :disabled="countDown==0?false:true" style="margin-left:10px;width:38%" @click="getCode">获取验证码<span v-if="countDown>0">({{countDown}})</span></el-button>
                  </el-form-item>
                  <el-form-item prop="userMail">
                      <el-input v-model="registerInfo.userMail" placeholder="请输入邮箱"></el-input>
                  </el-form-item>
                  <el-form-item prop="userPassword">
                      <el-input type="password" v-model="registerInfo.userPassword" placeholder="请输入密码，区分大小写，至少6个字符"></el-input>
                  </el-form-item>
                  <el-form-item >
                      <el-input type="password" v-model="userPassword2" placeholder="再次输入密码"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button style="width:100%" type="primary" @click="ToRegister">注册</el-button>
                  </el-form-item>
              <span>已有账号?</span> <el-link type='primary' @click="register=!register">立即登录>></el-link>

              </el-form>
          </div>
      </div>
      <div class="mid"></div>
  </div>
</template>

<script>
// import {getVertifiCode , Login} from '../api/login.js'
import {getVertifiCode, Login,getTicket,whetherScan,getCode,register} from '@/api/login.js'
// import {mapActions,mapMutations,mapAtate,mapGetters} from 'vuex'
export default {
    data(){
        return {
            shade:false,
            register:false,
            QRcode:'',
            loginway:'second',
            loading:false,
            form:{
                userAccount:'',
                userPassword:'',
                verificationCode:'1',
                language:'1',
                key:'',
                companyId:'',
            },
            verifiImg:'',
            rules: {
              userAccount: [
                {
                  required: true,
                  message: "请输入正确账号",
                  trigger: ["blur", "change"]
                },
                {
                  pattern: /^[a-zA-Z0-9_-]{1,30}$/,
                  message: "请输入正确格式"
                }
              ],
              userPassword: [
                { required: true, message: "请输入密码", trigger: "blur" }
              ],
              // verificationCode: [
              //   { required: true, message: "请输入验证码", trigger: "blur" }
              // ]
            },
            registerInfo:{
              code:'',
              userAccount:'',
              userMail:'',
              userName:'',
              userPassword:'',
              userPhone:'',
            },
            userPassword2:'',
            registerRules:{
              userPhone:[{required:true,message:'请输入手机号',trigger:'blur'},{pattern:/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,message:'请输入正确格式手机号'}],
              code:[{required:true,message:'请输入验证码',trigger:'blur'}],
              userAccount:[{required:true,message:'请输入用户账号',trigger:'blur'},{pattern: /^[a-zA-Z0-9_-]{6,16}$/,message: "请输入正确格式"}],
              userMail:[{required:true,message:'请输入邮箱',trigger:'blur'},{pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,message: "请输入正确格式"}],
              userName:[{required:true,message:'请输入用户名',trigger:'blur'}],
              userPassword:[{required:true,message:'请输入您的密码',trigger:'blur'},
              {pattern: /^[0-9A-Za-z]{6,20}$/,message: "请输入长度为6～20的密码"}
              ],
              // userPassword2:[{required:true,message:'再次输入您的密码',trigger:'blur'}],
              userPhone:[{required:true,message:'请输入手机号',trigger:'blur'},{pattern:/^1\d{10}$/,message:'请输入正确的手机号'}],
            },
            countDown:0,
        }
    },
    methods:{
      forget(){
        this.$router.push('/Register')
      },
      ToRegister(){
        this.$refs.register.validate(valid=>{
          if(!valid) return
          if(!this.userPassword2) return  this.$message.error('请再次输入确认您的密码')
          this.loading=true

          register({...this.registerInfo,userPassword:this.md5(this.registerInfo.userPassword)}).then(res=>{
            this.loading=false
            console.log(res);
            if(res.data.code==200){
              this.$message.success(res.data.message)
              this.$refs.register.resetFields()
              this.userPassword2=''
              this.loginway="second"
            }else{
              this.$message.error(res.data.message)
            }
          }).catch(()=>{
            this.loading=false
          })
        })
      },
      getCode(){
        if(this.registerInfo.userPhone.length<11) return this.$message.error('请输入正确格式手机号')
        this.loading=true
        this.countDown=60
        const time=setInterval(()=>{
          this.countDown--
          if(this.countDown==0){
            clearInterval(time)
          }
          console.log(this.countDown);
        },1000)
        getCode({type:1,userPhone:this.registerInfo.userPhone}).then(res=>{
          this.loading=false
        }).catch(()=>{
          this.loading=false
        })
      },
      getTicket(){
        this.loading=true
        getTicket().then(res=>{
          this.loading=false
          if(res.data.code==200){
            this.QRcode=`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.data.data}`
            this.ticket=res.data.data
            this.shade=false
            this.time=new Date().getTime()
            this.whetherScan()
          }else{
            this.$message.error(res.data.message)
          }
        })
      },
      whetherScan(){
        var current = new Date().getTime()
        const num =current-this.time
        if(num>1000*60*5||this.shade){
          this.shade=true
          this.time=new Date().getTime()
          return
        }
        whetherScan({ticket:this.ticket}).then(response=>{
          if(response.data.code==500){
            this.$message.error(response.data.message)
          }
          var token =window.sessionStorage.getItem('token')||''
          if(token) return 
          if(response.data.code!=200){
            this.shade=true
            return
          }
          if(response.data.code==200&&typeof response.data.data == 'object'){
            this.form.userAccount=response.data.data.userAccount
            this.toLoginStart(response.data.data.userPassword)
            return
          }else if(response.data.code==200){
            const timer = setTimeout(()=>{
                this.whetherScan()
                clearTimeout(this.timer)
            },3000)
          }
        })
      },
        async getVertify(){
            const {data}=await getVertifiCode()
            this.verifiImg=data.data.img
            this.form.key=data.data.key
            if(this.shade){
              this.getTicket()
            }
        },
        toLoginStart(psw){
            Login({...this.form,userPassword:psw}).then(res=>{
                this.loading=false
                if(res.data.code == 200){
                  this.timer=null
                  this.$message({
                    message: res.data.message,
                    type: "success",
                    showClose: true,
                    duration: 1000
                  });
                  window.sessionStorage.setItem('user',JSON.stringify({
                      headUrl:res.data.data.headUrl,
                      userAccount:res.data.data.userAccount,
                      userName:res.data.data.userName,
                      userId:res.data.data.userId
                  }))
                  this.$store.commit('setUser')
                  
                  window.sessionStorage.setItem('token',res.data.data.sessionId)
                  window.sessionStorage.setItem('menuList',JSON.stringify(res.data.data.menuTree))
                  const accredit =JSON.parse(window.sessionStorage.getItem('accredit')) || ''
                  if(!accredit){
                    this.$router.push(res.data.data.url)
                  }else {
                    this.$router.push('/shopAuthorization')
                  }
              }else{
                  this.$message({
                    message:res.data.message,
                    type:'warning'
                  })
                  this.getVertify()
              }
          })
        },
        reset(){
          this.$refs.form.resetFields()
        },
        login(){
          // console.log(psw);
          // console.log(this.form);
            this.loading=true
            this.$refs.form.validate((valid)=>{
                if(!valid){
                    this.$notify({
                        title:'警告',
                        message:'请填写完整账号密码和验证码',
                        type:'warning'
                    })
                    this.loading=false
                }else{
                  const password = this.md5(this.form.userPassword)
                  this.toLoginStart(password)
                }
            })
        },
        toIndexPage(){
          window.open('https://www.cangsoft.com','_blank')
        }
    },
    mounted(){
        this.getTicket()
        this.getVertify()
        
    },
    beforeDestroy(){
    }
}
</script>

<style scoped lang="scss">

.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f4fcfe;
  flex-direction: column;
  background-image: url("../assets/img/logoImg.jpg");
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: 100% 100%;
  .mid {
    width: 100%;
    height: 654px;
    background-color: #ffffff;
    position: relative;
    z-index: -1;
  }
  .content {
    height: 823px;
    width: 1200px;
    margin: 0 auto;
    .content1 {
      height: 163px;
      display: flex;
      position: relative;
      .cont1two {
        width: 461px;
        height: 48px;
        margin-left: 20px;
        margin-top: 40.75px;
        font-size: 50px;
        font-weight: bold;
        color: #ffffff;
        line-height: 48px;
      }
      .cont1one {
        // width: 167.9px;
        width: 288.9px;
        height: 109.8px;
        // img {
        // position: absolute;
        // left: 360px;
        // top: 115px;
        // margin-top: 31px;
        // margin-left: -360px;
        // width: 167.9px;
        // width: 275.9px;
        // height: 70.8px;
        // height: 109.8px;
        // }
      }
    }
    .content2 {
      height: 660px;
      // background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.22);
      .content2one {
        width: 599.9px;
        height: 488.7px;
        // background-color: #fff;
        img {
          width: 599.9px;
          height: 488.7px;
        }
      }
    }
  }
  .loginQRcode{
    width: 350px;
    border-radius: 10px;
    padding: 17px 40px 0;
    box-sizing: border-box;
    
    >div{
      position: relative;
      width: 250px;
      height: 250px;
      margin: auto;
      .shade{
        background:rgba(0,0,0,.9);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        color: orange;
        text-align:center;
        line-height: 250px;
        font-size: 100px;
        .refresh{
          position: absolute;
          bottom: 20px;
          color: white;
          font-size: 20px;
          left: 84px;
        }
      }
      >img{
        width: 100% !important;
        height: auto !important;
        padding: 0;
        margin: 0;
      }
    }
  }
  .loginForm{
    background-color: #fff;
    width: 350px;
    border-radius: 10px;
    padding: 28px 40px 0;
    
    box-sizing: border-box;
    .verificationItem {
      display: flex;
      align-items: center;
    }
    h1 {
      margin-left: 75px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      color: #333333;
      width: 200px;
    }
    h1:hover {
      color: skyblue;
    }
    .czbtn {
      background-color: #288a25;
      color: #ffffff;
      margin-left: 80px;
    }
    .elform-img {
      width: 148px;
      height: 40px;
      // background-color: pink;
      img {
        width: 148px;
        height: 40px;
        margin: 0;
      }
    }
  }
  .verificationInput {
    width: 160px;
  }
  .loginBtn {
    margin-right: 18%;
  }
  img {
    margin-left: 20px;
    width: 100px;
    height: 40px;
  }
}
::v-deep .verificationItem1 {
  height: 60px;
  line-height: 60px;
}
.input_width {
  width: 220px;
}
.onSubmitBtn {
  float: right;
  margin-top: 10px;
}
.radio_btn {
  width: 100%;
  margin-top: -25px;
}
.sayTake {
  width: 100%;
  height: 40px;
  margin-top: -22px;
  margin-bottom: 22px;
}
.el-select {
  width: 225px;
}
.imgage {
  position: absolute;
  left:93px;
  top: 59px;
  width: 260px !important;
  height: 60px !important;
  margin: 0 !important;
}
::v-deep .loginContent {
  overflow: hidden;
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-40%);
  height: 530px;
  margin: 0 !important;
  background: white;
  padding: 40px 20px 0px 20px;
  box-sizing: border-box;
  border-radius: 9px;
  .el-tabs__nav-wrap{
    padding: 0 90px;
  }
}
</style>